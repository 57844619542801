import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import logo from "../assets/essenlix-logo-20181029-v1-1540879800.jpg"

import { Link as RouterLink, useLocation } from 'react-router-dom'

import {NavButton} from "./NavButton.tsx"
import Divider from '@mui/material/Divider';


export const Footer = () => {
    return(
        <>
        <Box sx={{ display:"flex", my: 2, flexDirection: "row"}}>
            <Typography variant="body1" sx={{  mr: 1, my: 2}} >
            © 2023 Essenlix Corporation
            </Typography >   
            <Divider variant="middle" orientation="vertical" flexItem/>
            <a id="footer-icp-link" rel="nofollow" href={"https://beian.miit.gov.cn"} target='_blank' style={{"textDecoration": "none"}}> 
            <Typography variant="body1" sx={{ ml: 1, mr: 1, my: 2 }}>
            沪ICP备2023034755号
            </Typography>
            </a>
            <Divider variant="middle" orientation="vertical" flexItem/>
            <NavButton
            text={"Privacy Policy"}
            to={"/privacy"}
            />
        </Box>
        </>
        )
    }
