
import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { ReactInternetSpeedMeter } from 'react-internet-meter'
// import 'react-internet-speed-meter/dist/index.css'
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import DropzoneAreaExample from '../components/Dropzone.tsx';
import Login from '../components/Login.tsx';
import Divider from '@mui/material/Divider';





export const NetworkSpeed = () => {

    const [accessToken, setAccessToken] = useState(null);
    const [testStarted, setStarted] = useState(false);
    const [count, setCount] = useState(0);
    const [speed, setwifiSpeed] = useState(20);
    const imageUrl = "https://picsum.photos/200/300";
    const MAX_INTERVAL_ID = 10000000;
    const MAX_TEST_COUNT = 30;
    const MAX_SPEED = 200;

    const Max_totalChildren = 1;
    const divs = [...Array(Max_totalChildren).keys()];


    // count the test intervals
    useEffect(() => {
        if (speed != 0) {
            setCount(count +1);
        }
        else {
            setCount(0);
        }
    }, [speed])


    // stop testing when max counts reached
    useEffect(()=> {
        if (count >= MAX_TEST_COUNT) {
            stopChecker(null);
        }
    }, [count])


    const loadChecker = (e) => {
        setwifiSpeed(0);
        setCount(0);
        setStarted(true);
    }

    const stopChecker = (e) => {
        setStarted(false);
        for (let i = 1; i < MAX_INTERVAL_ID; i++) {
            window.clearInterval(i);
          }
    }

    
    return <>
        <Box 
          sx={{ flexGrow: 1, display: { xs: 'block', sm: 'block', textAlign: 'center' } }}
        >
        {accessToken? <>

            <Typography variant="h3" gutterBottom>
                    Test Network Speed
                </Typography>

            <Divider sx={{
                maxWidth: "500px",
                translate: "-50%",
                ml: "50%",
                mt: "70px",
                mb: "70px"
            }}></Divider>


            <Typography variant="h4" gutterBottom>
                Test Download Speed
            </Typography>

            <Button
                variant="contained"
                sx={{ my: 2 , mr:2, textAlign: 'center'}}
                onClick={(e) => loadChecker(e)}
                disabled={testStarted}
            >
                Start new test
            </Button>


            <Button
                variant="contained"
                sx={{ my: 2 , textAlign: 'center'}}
                onClick={(e) => stopChecker(e)}
                disabled={!testStarted}
            >
                Stop test
            </Button>

            {testStarted && 
            <Box
            // display="flex" justifyContent="center" 
            // sx={{ flexDirection: 'row', flexWrap: "true", borderRadius: "50%" }}
            sx= {{
                // position: 'relative',
                width: '100%',
                // borderRadius: '50%',
                // backgroundColor: 'lightblue',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                
            }}
            >

                {divs.map((id) => {

                    const calculateChildPosition = (index, totalChildren) => {
                        const angle = (index / totalChildren) * 2 * Math.PI; // Calculate the angle
                        const radius = 150; // Radius of the circle
                        const x = 130 + radius * Math.cos(angle); // X coordinate
                        const y = 140 + radius * Math.sin(angle); // Y coordinate
                        console.log(`${x} ${y}, ${Math.sqrt(x**2+ y**2)}`)
                        return { left: `calc(${x}px)`, top: `calc(${y}px)`, transform: `rotate(${Math.PI/2 +angle}rad)` };
                    };

            
                    const progress = speed / MAX_SPEED * Max_totalChildren > id ? Math.min(1, speed / MAX_SPEED * Max_totalChildren - id) : 0
                    // console.log(id, progress)

                    return <Box
                        key={`progress-${id}-box`} 
                        sx={{
                            flexDirection: "row",
                            display: "flex",
                            width: "md"
                        }}
                        >
                            <Box>0 Mbp/s</Box>
                                <LinearProgress 
                                    key={`progress-${id}`} 
                                    color="inherit"  
                                    variant="determinate" 
                                    value={ progress * 100 }
                                    sx={{
                                        // ...calculateChildPosition(id+divs.length-1, divs.length*2-2),
                                        // position: "absolute", 
                                        transition :"none",
                                        width: `${300/Max_totalChildren}px`,
                                        height: '20px',
                                        mr: "5px",
                                        // transform: `rotate(deg)`
                                        // transform: 'translate(140px, 140px)', 
                                        // borderRadius: "50%",
                                        // mt: "-20px",
                                        // borderTopWidth: "40px",
                                        // mr: "50px"
                                        
                                        // clip: `rect(0px, 50px, 100px, 0px)`
                                        // transform: 'rotate(45deg)
                                    }}/>
                                    {/* { `${Math.round(MAX_SPEED * id/Max_totalChildren)}`} */}
                                    <Box>200 Mbp/s</Box>
                                    </Box>
                    })}
                </Box>
            }

            <Typography
                    variant='h6'
                    sx={{ my: 2 , textAlign: 'center'}}
                >{`Network Download Speed ${speed} Mbps`}
            </Typography>

            <Divider sx={{
                maxWidth: "500px",
                translate: "-50%",
                ml: "50%",
                mt: "70px",
                mb: "70px"
            }}></Divider>

            {testStarted && 
            
            <ReactInternetSpeedMeter  
                    txtSubHeading=" Mbps"
                    outputType="alert"
                    customClassName={null}
                    txtMainHeading={"Testing internet download speed"}
                    pingInterval={1000} // milliseconds 
                    thresholdUnit='megabyte' // "byte" , "kilobyte", "megabyte" 
                    threshold={100}
                    clearInterval={true}
                    imageUrl={imageUrl}
                    downloadSize="1781287"  //bytes
                    callbackFunctionOnNetworkDown={(speed)=>console.log(`Internet speed is down ${speed}`)}
                    callbackFunctionOnNetworkTest={(speed)=>{
                        setwifiSpeed(speed)
                    }}
                ></ReactInternetSpeedMeter>}

            <DropzoneAreaExample accessToken={accessToken} setToken={setAccessToken}/>

            <Divider sx={{
                maxWidth: "500px",
                translate: "-50%",
                ml: "50%",
                mt: "70px",
                mb: "30px"
            }}></Divider>
            
            <Button
                variant="contained"
                sx={{ my: 2 , textAlign: 'center'}}
                onClick={(e)=>{setAccessToken(null)}}
            >Logout
            </Button>
            </>:<Login setToken={setAccessToken}/>}
        </Box>
    </>
            
};
