
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import logo from "../assets/essenlix-logo-20181029-v1-1540879800.jpg"

import { Link as RouterLink, useLocation } from 'react-router-dom'

export const NavButton = (props) => {
    return (
        <Typography sx = {{
            fontWeight: 'bold',
            // color: '#FFF',
            my: 2,
            ml: 1,
            mr: 1
        }} variant='body1' className="m-3" key={`page-header-button-${props.text}`}>
            <RouterLink  to={props.to} style={{"textDecoration": "none"}} className='nav-link' type="button">
             {props.text}
            </RouterLink>
        </Typography>
    )
  }