
import * as React from 'react';
// import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
// import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
// import Drawer from '@mui/material/Drawer';
// import IconButton from '@mui/material/IconButton';
// import List from '@mui/material/List';
// import ListItem from '@mui/material/ListItem';
// import ListItemButton from '@mui/material/ListItemButton';
// import ListItemText from '@mui/material/ListItemText';
// import MenuIcon from '@mui/icons-material/Menu';
// import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
// import Button from '@mui/material/Button';
// import logo from "../assets/essenlix-logo-20181029-v1-1540879800.jpg"
import banner from "../assets/516860891-back3.jpg"
// import { useDispatch } from 'react-redux';
import { Link as RouterLink, useLocation } from 'react-router-dom'
import { DrawerAppBar } from '../components/Header.tsx';
import {Footer} from "../components/Footer.tsx"

const newsUrl = "https://www.prnewswire.com/news-releases/essenlix-partnering-with-roche-norway-won-norway-ostfold-hospital-systems-patient-at-home-test-innovation-partnership-that-will-use-essenlixs-imost-as-test-platform-301425900.html"


export const Home = () => {
  return (
      <>
      <DrawerAppBar>
        <Typography variant="h3" sx={{ my: 2 , textAlign: 'center'}} >
        iMOST (Instant Mobile Self-Test), Transforming Digital Health and Beyond
        </Typography>

        <Box sx ={{textAlign: 'center'}}>
          <Typography variant="h6" sx={{ my: 2 }}>
          Accurate Instant Mobile Health Self-Test at Your Finger Tips, Anywhere, Anytime, Available and Affordable To Everyone
          </Typography>

          <a href={newsUrl} target='blank' style={{"textDecoration": "none"}}> 
          <Typography variant="h5" sx={{ my: 2 }}>
          
          Press Release: Nov 16, 2021 - Essenlix, Partnering with Roche Norway, Won Norway Østfold Hospital System's "Patient At-Home Test" Innovation Partnership That Will Use Essenlix's iMOST™ as Test Platform
          </Typography>
          </a>

        </Box>
        <Box 
          sx={{ flexGrow: 1, display: { xs: 'block', sm: 'block', textAlign: 'center' } }}
        >
              <img src={banner } alt='Logo'
                className="banner"
              />
              {/* <Box
                component="img"
                sx={{maxWidth: 900}}
                alt="banner"
                src="../assets/516860891-back3.jpg"
              /> */}
              <Typography>
              Image of iMOST Model X (X-1 CBC is CE Mark Cleared, Not FDA Cleared)
              </Typography>
        </Box>

      <Box sx={{ my: 2}}>

      <Typography variant="body1" sx={{ my: 2 }}>
        Essenlix, a digital health and wellness company, develops a game-changing instant mobile health self-test platform, iMOST, for allowing, in addition to professional use, a layperson to accurately perform a diagnostic/health test her/himself, anywhere (at home, point-of-care, clinics, hospitals, etc.), anytime, using a single drop of body fluid and a hand-held portable device (some are smartphone based), within minutes, at a low cost, with the results sent immediately to professionals.
      </Typography>
      <Typography variant="body1" sx={{ my: 2 }}>
      iMOST™ (Instant Mobile Self-Test), first of the type, is based on outside-the-box thinking and a fundamentally different paradigm, that is designed to be intelligently fault tolerant, delivering clinical lab-quality test results using a simple device, even when a sample, device, process, and operation are imperfect. iMOST™ is also designed to be versatile, using one unique sample holder platform and one instrument to test different sample types (blood, saliva, urine, swab, sputum, breath, mucus, tissue, etc.), different assays (immunoassay, colorimetric assay, cytology and pathology, nucleic acid assay, etc.) and different biomarkers (cells, proteins, nucleic acids, and small molecules). The new paradigm is being realized through uniquely implementing nanotechnology, advance imaging, Nano-Enabled Artificial intelligence/Machine-learning (NEAM) and new bio/chemical processes into diagnostic testing.
      </Typography>
      <Typography variant="body1" sx={{ my: 2 }}>
      Essenlix's iMOST platform is launching its first product "iMOST-CBC" for complete blood count (CBC) testing for research and, pending FDA approval, diagnostics (iMOST X-1 blood count is CE Mark cleared). In parallel, Essenlix has several other assays in its product pipeline.
      </Typography>
      <Typography variant="body1" sx={{ my: 2 }}>
      Diagnostic lab-testing is a vital and necessary procedure for diagnosing diseases and monitoring health conditions. The iMOST platform is designed to remove one of the largest barriers in digital health by replacing a conventional, inconvenient, and time-consuming lab-test with an instant mobile self-test platform, providing a key link between personal health, remote monitoring and treatment, and e-health services. Essenlix aims to make iMOST available to most of the world’s 7 billion population.
      </Typography>
      
      <Typography variant="body1" sx={{ my: 2 }} >
        Products Not FDA Cleared
      </Typography>

      </Box>

      <Divider/>
      <Footer/>
      
      
      
      </DrawerAppBar>
      
      </>
  )
};