


import React, { Component, useEffect, useState } from "react";
import { DropzoneArea } from "mui-file-dropzone";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import axios, { CancelTokenSource } from 'axios';
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';



const DropzoneAreaExample = (props) => {

  const [files, setFiles] = useState<File[]>([]);
  const [jwtInvalid, setjwtInvalid] = useState(false);
  const [totalBytes, setTotalBytes] = useState(0);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [uploadSpeed, setUploadSpeed] = useState<string | null>(null);
  const [uploading, setUploading] = useState(false);
  const [cancelTokenSource, setCancelTokenSource] = useState<CancelTokenSource | null>(null);


  // Handle file change
  const handleChange = (newFiles: File[]) => {
    setFiles(newFiles);
  };

  // cancel upload
  const handleCancel = () => {
    if (cancelTokenSource) {
      cancelTokenSource.cancel('Upload canceled by the user.');
      setCancelTokenSource(null); // Clear the cancel token source
    }
  };


  // handle jwtInvalid
  const handleJwtInvalid = () => {
    setjwtInvalid(true);
    setTimeout(props.setToken(null), 5000);
  }

  // Calculate file speed
  const handleUploadProgress = (progressEvent, startTime) => {
    const elapsedTime = (Date.now() - startTime) / 1000; // Time in seconds
    const totalBytes = progressEvent.total || 0.0000001;
    const uploadedBytes = progressEvent.loaded;
    const progress = (uploadedBytes / totalBytes) * 100;
    setUploadPercentage(Math.round(progress));

    if (elapsedTime > 0 && uploadedBytes > 0) {
      const uploadedMB = uploadedBytes / (1024 * 1024); // Convert bytes to MB
      const speedMbps = (uploadedMB * 8) / elapsedTime; // Speed in Mbps

      setUploadSpeed(speedMbps.toFixed(2)); // Update upload speed
    }
  }

  useEffect(() => {
    console.log("files changed")
  }, [files]);

  const uploadFile = async () => {

    // Create a new CancelToken
    const source = axios.CancelToken.source();
    setCancelTokenSource(source);
    setUploading(true)
    setTotalBytes(files.reduce((acc, file) => acc + file.size, 0)); // Total file size

    // verify token
    const validCheck = await axios.post('https://uploadtestendpoint.essenlixcloud.com.cn/api/token/verify/', {
      'token': props.accessToken
    }).then((response) =>{
      // console.log(response)
      if (response.status==401){
        console.log("invalid token")
        handleJwtInvalid()
      }
    }).catch(function (error) {
      // console.error('Error verify token', error);
      handleJwtInvalid()
    });

    
    // start actual uploading
    const formData = new FormData();
    files.forEach(file => {
      formData.append('file', file);
    })
    const startTime = Date.now(); // Record start time
    const response = await axios.post('https://uploadtestendpoint.essenlixcloud.com.cn/uploadtest/', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `JWT ${props.accessToken}`
      },
      onUploadProgress: (e)=>handleUploadProgress(e, startTime),
      cancelToken: source.token, // Attach the cancel token
    }).then(function (response) {
      setUploading(false)
      if (response.status==401){
        console.log("invalid token")
        handleJwtInvalid()
      }
      console.log('File uploaded successfully:', response.data);
    })
    .catch(function (error) {
      // console.error('Error uploading file:', error);
      setUploading(false)
    });

  
  };
  
    return <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      gap: 2,
    }}
    >
      <Typography variant="h4" gutterBottom>
        Test Upload Speed
      </Typography>

    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        ml: "50%",
        translate: "-50%",
        maxWidth: '500px',
        gap: 2,
      }}
    >
      
      <DropzoneArea 
        maxFileSize={1073741824} // 1G
        filesLimit={1}
        fileObjects={(files.map(file=>file))}
        onChange={(loadedFiles)=>handleChange(loadedFiles as File[])} 
      />
      <Button
        variant="contained"
        sx={{  textAlign: 'center'}}
        onClick={uploadFile}
        disabled={uploading || files.length===0}
      >{"Upload"}
      </Button>

      <Button
        variant="contained"
        sx={{  textAlign: 'center'}}
        onClick={handleCancel}
        disabled={!uploading}
      >{"Cancel Upload"}
      </Button>

      {uploadPercentage > 0 && (

        <Box>
          <Typography variant="h6">Upload Size: {Math.round(totalBytes / (1024 * 1024) * 1000)/ 1000} MB</Typography>
          <Typography variant="h6">Upload Progress: {uploadPercentage.toFixed(2)}%</Typography>
          {uploadSpeed && (
            <Typography variant="h6">Upload Speed: {uploadSpeed} Mbps</Typography>
          )}
        </Box>
      )}
    </Box>
    {jwtInvalid && (
      <Alert icon={<CheckIcon fontSize="inherit" />} severity="error">
      Login has expired, returning to login in 5s
    </Alert>
    )}
    </Box>;

}

export default DropzoneAreaExample;