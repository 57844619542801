
// https://www.emgoto.com/react-table-of-contents/#make-your-browser-smoothly-scroll-to-headings
import * as React from 'react';
import { useState, useEffect } from 'react';

const getNestedHeadings = (headingElements) => {
    const nestedHeadings = [];
  
    headingElements.forEach((heading, index) => {
      const { innerText: title, id } = heading;
  
      if (heading.nodeName === "H4") {
        nestedHeadings.push({ id, title, items: [] });
      } else if (heading.nodeName === "H5" && nestedHeadings.length > 0) {
        nestedHeadings[nestedHeadings.length - 1].items.push({
          id,
          title,
        });
      }
    });
  
    return nestedHeadings;
  };

const useHeadingsData = () => {
    const [nestedHeadings, setNestedHeadings] = useState([]);
  
    useEffect(() => {
      const headingElements = Array.from(
        document.querySelectorAll("h4, h5")
      );
  
      const newNestedHeadings = getNestedHeadings(headingElements);
      console.log(newNestedHeadings)
      setNestedHeadings(newNestedHeadings);
    }, []);
  
    return { nestedHeadings };
  };

const Headings = ({ headings }) => (


    <ul>
      {headings.map((heading) => (
        <li key={heading.id}>
          <a href={`#${heading.id}`}>{heading.title}</a>
          {heading.items.length > 0 && (
          <ul>
            {heading.items.map((child) => (
              <li key={child.id}>
                <a href={`#${child.id}`}>{child.title}</a>
              </li>
            ))}
          </ul>
        )}
        </li>
      ))}
    </ul>
);

const TableOfContents = () => {

    const { nestedHeadings } = useHeadingsData();

    return (
        <nav aria-label="Table of contents">
            <Headings headings={nestedHeadings} />
        </nav>
    );
};

export default TableOfContents;