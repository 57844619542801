

import React, { Component, useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import axios from 'axios';

const Login = (props) => {
    // const [accessToken, setToken] = useState(null);

    const requestToken = async (username, password) => {
        const response = await axios.post('https://uploadtestendpoint.essenlixcloud.com.cn/api/token/', 
        {
          'username':username,
          'password':password
        })
        .then(function (response) {
        //   console.log(response);
          props.setToken(response.data.access);
        })
        .catch(function (error) {
        //   console.log(error);
        });
      }
    
      const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        // console.log({
        //   email: data.get('username'),
        //   password: data.get('password'),
        // });
        // props.setToken("daqwfdaw");
        requestToken(data.get('username'), data.get('password'));
      };

      return <Box
      component="form"
      onSubmit={handleSubmit}
      noValidate
      sx={{
        display: 'flex',
        flexDirection: 'column',
        ml: "50%",
        translate: "-50%",
        maxWidth: '500px',
        gap: 2,
      }}
    >
      <Typography variant="h5" gutterBottom>
        Sign-in
      </Typography>

    <FormControl>
      <FormLabel>username:</FormLabel>
      <TextField
        id="username"
        type="username"
        name="username"
        placeholder="your username"
        // autoComplete="email"
        autoFocus
        required
        fullWidth
        variant="outlined"
      />

      <FormLabel htmlFor="password">Password</FormLabel>
      <TextField 
        name="password"
        placeholder="••••••"
        type="password"
        id="password"
        autoComplete="current-password"
        autoFocus
        required
        fullWidth
        variant="outlined"
      />
    </FormControl>
    
    
    <Button
      type="submit"
      fullWidth
      variant="contained"
      // onClick={(e)=>(e)}
    >
      Sign in
    </Button>
    </Box>;
}


export default Login;