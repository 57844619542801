// import { Image } from "@mui/icons-material"
// import {ReactComponent as EssenlixLogo} from '../assets/essenlix-logo-20181029-v1-1540879800.jpg';


// const Header = () => {
//     return (
//         <>
//             <Toolbar>
//             <EssenlixLogo className="essenlix-logo" key={"essenlix-logo-nav"}/>
//             <div>

//             </div>
//             </Toolbar>
//         </>
//     )
// }

import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import logo from "../assets/essenlix-logo-20181029-v1-1540879800.jpg"
import { Link as RouterLink, useLocation } from 'react-router-dom'

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
  children?: React.ReactNode;
}

const drawerWidth = 240;
const navItems = [
  {text: 'Home', to: "/"}
];

export function DrawerAppBar(props: Props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      {/* <Typography variant="h6" sx={{ my: 2 }}>
        MUI
      </Typography> */}
      <RouterLink  to={"/"} style={{"textDecoration": "none", 'width' : "100%"}} className='nav-link' type="button">
          <img src={logo} alt='Logo'/></RouterLink>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item.text} disablePadding>
            {/* <ListItemButton sx={{ textAlign: 'center' }}>
              <ListItemText primary={item.text} />
            </ListItemButton> */}
        <ListItemButton sx={{ textAlign: 'center' }}>
            <RouterLink  to={item.to} style={{"textDecoration": "none", 'width' : "100%"}} className='nav-link' type="button">
            {item.text}
          </RouterLink>
        </ListItemButton>

          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar component="nav" position="fixed" elevation={0}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Box 
            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
          >
            <RouterLink  to={"/"} style={{"textDecoration": "none", 'width' : "100%"}} className='nav-link' type="button">
            <img src={logo} alt='Logo'/></RouterLink>
          </Box>
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            {navItems.map((item) => (
              <Button key={item.text} color='secondary' >
                <RouterLink  to={item.to} style={{"textDecoration": "none"}} className='nav-link' type="button">
                {item.text}
                </RouterLink>
              </Button>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </nav>

      <Box className = "main" component="main" sx={{ p: 3, width: "75vw", ml: "auto", mr: " auto" }}>
        <Toolbar />
        {props.children}
      </Box>
    </Box>
  );
}