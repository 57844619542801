import './App.css';
import {Home} from './containers/Home.tsx';
import { Privacy } from './containers/Privacy.tsx';
import {BrowserRouter as Router, Route, Routes, Navigate} from 'react-router-dom'


import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import { orange, green, purple, yellow, brown } from '@mui/material/colors';
import {NetworkSpeed} from './containers/NetworkSpeed.tsx';


const outerTheme = createTheme({
  typography: {
      h3:{
        fontFamily:[
          'Barlow Condensed', 
          'sans-serif'
        ].join(','),
        color: green[600]
      }, 
      h5:{
        fontFamily: 'Montserrat',
        color: '#963'
      },
      h6:{
        fontFamily:[
          'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif', 
        ].join(',')
      }
  },
  palette: {
    primary: {
      main: '#fff',
    },
    secondary: {
      main: green[700],}
  },
});


function App() {
  return (
    <ThemeProvider theme={outerTheme}>
    <div className="App">
      <Router >
      <Routes>
      <Route exact path='/' element={<Home/>}/>
      <Route exact path='/internet-speed' element={<NetworkSpeed/>}/>
      <Route exact path='/privacy' element={<Privacy/>}/>
      </Routes>
      </Router>
    </div>
    </ThemeProvider>
  );
}

export default App;
